<template>
    <a-modal class="export-incidents-to-csv-modal" centered :width="800" v-model="showModal" :footer="null"
        title="Export Incidents to CSV">
        <loading-screen :is-loading="isSaving"></loading-screen>

        <!-- Stage 0 -->
        <div v-if="stage == 0">
            <!-- Stage 0 Header -->
            <div class="stage-header">
                <div class="left">
                    You have selected {{ selectedIncidentIds.length }}
                    {{ selectedIncidentIds.length == 1 ? "incident" : "incidents" }}
                </div>
                <div class="right">
                    <a-button type="primary" @click.prevent="goToNextStage" :disabled="selectedIncidentIds.length == 0">
                        Next</a-button>
                </div>
            </div>
            <!-- / Stage 0 Header -->

            <!-- Incidents wrapper -->
            <div class="incidents-wrapper">
                <div style="margin-bottom: 10px">
                    <a-button size="small" style="margin-right: 10px;" @click.prevent="selectAll">Select All</a-button>
                    <a-button size="small" @click.prevent="deselectAll">Deselect All</a-button>
                </div>
                <a-row :gutter="10" type="flex">
                    <a-col v-for="(incident, i) in incidents" :key="i" :span="12" class="incident-list-item-col">
                        <div class="incident-list-item" @click.prevent="toggleIncidentSelected(incident.id)"
                            :class="{ selected: isIncidentSelected(incident.id) }">
                            <div class="left">
                                <incident-thumbnail size="large" :incident-id="incident.id"
                                    :incident-owner-id="incident.ownerId" :created-by="incident.createdBy"
                                    :tenant-id="tenantId"></incident-thumbnail>
                            </div>
                            <div class="middle">
                                {{ incident.displayName }}
                            </div>
                            <div class="right">
                                <a-icon v-if="isIncidentSelected(incident.id)" theme="twoTone" two-tone-color="#52c41a"
                                    type="check-circle" />
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
            <!-- / Incidents wrapper -->
        </div>
        <!-- / Stage 0 -->

        <!-- Stage 1 -->
        <div v-if="stage == 1">
            <!-- Stage 1 Header -->
            <div class="stage-header">
                <div class="left">
                    <a-button @click.prevent="goBack" type="default"> Previous </a-button>
                </div>
                <div class="right">
                    <a-button @click.prevent="exportToCsv" :disabled="!canExportToCsv" type="primary">
                        Export to CSV
                    </a-button>
                </div>
            </div>
            <!-- / Stage 1 Header -->

            <!-- Reason -->
            <div class="filename-wrapper">
                <a-form-item label="Filename">
                    <a-input size="large" v-model="filename" suffix=".csv"></a-input>
                </a-form-item>
            </div>
            <!-- / Reason -->
        </div>
        <!-- / Stage 1 -->
    </a-modal>
</template>
  
<script>
import IncidentThumbnail from "../../../components/Incidents/IncidentThumbnail.vue";
import presences from "../../../api/presences";
import LoadingScreen from "../../../components/LoadingScreen.vue";
const _ = require("lodash");
import { ExportToCsv } from 'export-to-csv';

export default {
    props: ["visible", "incidents", "tenantId", "myPresenceId"],
    components: { IncidentThumbnail, LoadingScreen },
    data() {
        return {
            stage: 0,
            selectedIncidentIds: [],
            isSaving: false,
            filename: ""
        };
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.stage = 0;
                this.selectedIncidentIds = [];
                this.reason = "";
            }
        },
    },
    computed: {
        showModal: {
            get() {
                return this.visible;
            },
            set(val) {
                if (!val) {
                    this.$emit("close-modal");
                }
            },
        },

        canExportToCsv() {
            return this.selectedIncidentIds.length && this.filename.trim().length;
        },
    },
    methods: {
        selectAll() {
            this.selectedIncidentIds = _.map(this.incidents, 'id');
        },

        deselectAll() {
            this.selectedIncidentIds = [];
        },

        toggleIncidentSelected(incidentId) {
            this.selectedIncidentIds = _.xor(this.selectedIncidentIds, [incidentId]);
        },

        isIncidentSelected(incidentId) {
            return this.selectedIncidentIds.includes(incidentId);
        },

        goBack() {
            if (this.stage > 0) {
                this.stage -= 1;
            }
        },

        goToNextStage() {
            if (this.stage == 0 && this.selectedIncidentIds.length == 0) {
                return false;
            }
            this.stage += 1;
        },

        async exportToCsv() {
            let vm = this;
            vm.isSaving = true;

            let incidents = [];
            let cachedPresences = [];

            // _.each(this.selectedIncidentIds, incidentId => {
            //     let incident = _.find(this.incidents, { id: incidentId });
            //     incidents.push({
            //         id: incident.id,
            //         name: incident.displayName,
            //         created: incident.created,
            //         createdBy: await 
            //         lastHumanActivity: incident.lastHumanActivity
            //     });
            // });

            for (const incidentId of this.selectedIncidentIds) {
                let incident = _.find(this.incidents, { id: incidentId });
                let presence = _.find(cachedPresences, { id: incident.createdBy });
                if (!presence) {
                    presence = await presences.getPresence(this.tenantId, incident.createdBy).then(r => r.data).catch(() => null);
                }

                let firstResponderPresence = null;
                if (
                    incident['content']
                    && incident['content']['$v']
                    && incident['content']['$v']['firstResponder']
                    && incident['content']['$v']['firstResponder'] !== null
                ) {
                    firstResponderPresence = _.find(cachedPresences, { id: incident['content']['$v']['firstResponder'] });
                    if (!firstResponderPresence) {
                        firstResponderPresence = await presences.getPresence(this.tenantId, incident['content']['$v']['firstResponder']).then(r => r.data).catch(() => null);
                    }
                }

                let firstRespondedAt = null;
                if (
                    incident['content']
                    && incident['content']['$v']
                    && incident['content']['$v']['firstResponded']
                    && incident['content']['$v']['firstResponded'] !== null
                ) {
                    firstRespondedAt = incident['content']['$v']['firstResponded']
                }

                incidents.push({
                    id: incident.id,
                    name: incident.displayName,
                    created: incident.created,
                    createdBy: presence ? presence.displayName : 'Unknown',
                    lastHumanActivity: incident.lastHumanActivity,
                    firstResponder: firstResponderPresence ? firstResponderPresence.displayName : '',
                    firstRespondedAt: firstRespondedAt ? firstRespondedAt : ''
                });
            }

            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: this.filename,
                filename: this.filename,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: false,
                headers: ['ID', 'Name', 'Created At', 'Created By', 'Last Human Activity', 'First Responder', 'First Responded At']
            };

            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(incidents);

            vm.isSaving = false;
            vm.showModal = false;
        }
    },
};
</script>
  
<style lang="scss">
.export-incidents-to-csv-modal {
    .incidents-wrapper {
        max-height: 350px;
        overflow-y: scroll;
        padding-right: 10px;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }

    .incident-list-item-col {
        margin-bottom: 10px;
    }

    .incident-list-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        border: 1px solid #eee;
        padding: 8px 9px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            background: #f9f9f9;
        }

        &.selected {
            background: #eee !important;
        }

        .left {
            flex-shrink: 1;
        }

        .middle {
            flex-grow: 1;
            padding-left: 15px;
        }

        .right {
            flex-shrink: 1;

            .anticon {
                font-size: 20px;
                margin-right: 4px;
            }
        }
    }

    .stage-header {
        margin-bottom: 20px;
        background: #f7f8fa;
        border-radius: 10px;
        padding: 10px;
        display: flex;

        .left {
            flex-grow: 1;
            display: flex;
            align-items: center;
            font-weight: 500;
        }

        .right {
            flex-shrink: 1;
            text-align: right;
        }
    }
}
</style>